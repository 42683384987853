import { useMutation, useQuery } from '@tanstack/react-query';
import { useFlag } from '@unleash/proxy-client-react';
import { useMemo } from 'react';

import backendService from '../services/backendService';
import { TenantLicense } from '../utils/tenantPlanUtils';
import useMe from './useMe';
import { useMetrics } from './useMetrics';
import useRouterMatch from './useRouterMatch';
export const tenantCacheKey = 'tenant';

export type IndexedTenant = {
  tenantId: string;
  tenantName: string;
  isEnterprise?: boolean | null; // Deprecated - used by pods enterprise - dont migrate data before pod is 100% shutoff
  districtId?: string | null;
  createdAt?: number | null;
  createdBy?: string | null;
  isPremium?: boolean | null; // Deprecated - used by pods - dont migrate data before pod is 100% shutoff
  isCuripodPremium?: boolean | null;
  isCuripodEnterprise?: boolean | null;
  curipodTrialExpirationDate?: number;
};

function useTenant() {
  const { tenantId: activeTenantId } = useRouterMatch();
  const { data, isFetching, refetch } = useQuery<IndexedTenant | undefined>(
    [tenantCacheKey, activeTenantId],
    _ => {
      if (!activeTenantId) return undefined;
      return backendService.instance.tenant.get(activeTenantId);
    },
    { staleTime: 1000 * 60 * 60 },
  );

  const metrics = useMetrics();
  const { mutate } = useMutation(
    async () => {
      metrics.logEvent('Trial.Started');
      await backendService.instance.users.startTrial();
    },
    {
      onSuccess: () => {
        refetch();
      },
    },
  );

  const { me: tenantUser } = useMe();
  const isReverseTrialEnabled = useFlag('REVERSE_TRIAL_MVP');
  const tenantPlan = useMemo(
    () =>
      new TenantLicense(
        data?.isCuripodEnterprise ?? false,
        data?.isCuripodPremium ?? false,
        data?.curipodTrialExpirationDate,
        tenantUser?.schoolCountry ?? undefined,
        tenantUser?.schoolState ?? undefined,
        isReverseTrialEnabled,
      ),
    [data, tenantUser, isReverseTrialEnabled],
  );

  return {
    data,
    isFetching,
    tenantPlan,
    startTrial: mutate,
  };
}

export default useTenant;
