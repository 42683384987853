export class TenantLicense {
  label: string;
  coarseLevel: LicenseCoarseLevels;
  fineLevel: LicenseFineLevels;
  trialExpirationDate: number | null;
  country: LicenseCountries | null;
  state: LicenseStates | null;

  _isTrialFlagEnabled: boolean;

  public canUnlockPremium(): boolean {
    return this.isFreemium();
  }

  public canUnlockTrial(): boolean {
    return (
      (this.isFreemium() || this.isPremium()) &&
      this.country === LicenseCountries.US &&
      this._isTrialFlagEnabled
    );
  }

  public isTrial(): boolean {
    return this.fineLevel === LicenseFineLevels.trial;
  }

  public isEnterprise(): boolean {
    return this.coarseLevel === LicenseCoarseLevels.enterprise;
  }

  public isPremium(): boolean {
    return this.coarseLevel === LicenseCoarseLevels.premium;
  }

  public isFreemium(): boolean {
    return this.coarseLevel === LicenseCoarseLevels.freemium;
  }

  constructor(
    isCuripodEnterprise: boolean,
    isCuripodPremium: boolean,
    curipodTrialExpirationDate?: number,
    country?: string,
    state?: string,
    isTrialEnabled?: boolean,
  ) {
    if (isCuripodEnterprise) {
      this.label = 'Enterprise';
      this.coarseLevel = LicenseCoarseLevels.enterprise;
      this.fineLevel = LicenseFineLevels.full;
    } else if (isTrialEnabled && (curipodTrialExpirationDate ?? 0) > Date.now()) {
      const formattedDate = new Intl.DateTimeFormat('en-US', {
        day: 'numeric',
        month: 'short',
        year: 'numeric',
      }).format(new Date(curipodTrialExpirationDate ?? 0));
      this.label = '⌛ ' + formattedDate;
      this.coarseLevel = LicenseCoarseLevels.enterprise;
      this.fineLevel = LicenseFineLevels.trial;
    } else if (isCuripodPremium) {
      this.label = 'Premium';
      this.coarseLevel = LicenseCoarseLevels.premium;
      this.fineLevel = LicenseFineLevels.full;
    } else {
      this.label = 'Freemium';
      this.coarseLevel = LicenseCoarseLevels.freemium;
      this.fineLevel = LicenseFineLevels.full;
    }
    this.trialExpirationDate = curipodTrialExpirationDate ?? null;
    this.country = this.parseCountry(country);
    this.state = this.parseState(state);
    this._isTrialFlagEnabled = isTrialEnabled ?? false;
  }

  private parseCountry(country?: string): LicenseCountries | null {
    if (!country) {
      return null;
    } else if (country === 'US' || country === 'United States of America') {
      return LicenseCountries.US;
    }
    return LicenseCountries.Other;
  }

  private parseState(state?: string): LicenseStates | null {
    if (!state) {
      return null;
    } else if (state === 'TX') {
      return LicenseStates.Texas;
    }
    return LicenseStates.Other;
  }
}

export enum LicenseCoarseLevels {
  enterprise = 'enterprise',
  premium = 'premium',
  freemium = 'freemium',
}

export enum LicenseFineLevels {
  trial = 'trial',
  full = 'full',
}

export enum LicenseCountries {
  US = 'us',
  Other = 'other',
}

export enum LicenseStates {
  Texas = 'TX',
  Other = 'other',
}
